import { ForkLeft, KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Collapse, Paper } from "@mui/material";
import "./help-and-support.css";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { DialogForm } from "@components/index";
import { RAISE_A_TICKET } from "@constants/forms";
import { getFormJson } from "@utils/formUtils";
import { raiseTicket } from "@services/ticketsService";
import { useAuth } from "@contexts/AuthContext";
import { toast } from "react-toastify";

const FAQsData = [
    {
        question: "What is a resume parser and how does it work?",
        answer:
          "A resume parser is a tool or software that automatically extracts key details like contact info, experience, and skills from resumes using AI and ML, organizing the data for quick review and saving you time.",
      },
      {
        question: "What formats does the resume parser support?",
        answer:
          "Our resume parser supports PDF, DOC, DOCX, and TXT formats, ensuring compatibility with most resume submissions.",
      },
      {
        question: "How accurate is the resume parsing technology?",
        answer:
          "Our parser achieves over 95% accuracy in data extraction, minimizing errors and ensuring reliable candidate information.",
      },
      {
        question: "Can the resume parser integrate with my existing systems?",
        answer:
          "Yes, our resume parser seamlessly integrates with popular Applicant Tracking Systems (ATS) and HR software, fitting smoothly into your existing workflow.",
      },
      {
        question: "How does the resume parser improve the hiring process?",
        answer:
          "By automating resume screening, our parser speeds up your recruitment process, enhances accuracy, and helps you quickly identify top candidates.",
      },
      {
        question: "Is my data secure with your resume parser?",
        answer:
          "Absolutely. We prioritize data security with robust measures to protect your sensitive information and ensure confidentiality.",
      },
      {
        question: "How can I get started with the resume parser?",
        answer:
          "Just sign up for a free trial on our website, and we’ll guide you through the quick and easy setup process.",
      },  
    // {
    //     question : "How long can I enjoy free trial service?",
    //     answer : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
    // },
    // {
    //     question : "Do I need to add any Credit Card to get free trial service?",
    //     answer : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
    // },
    // {
    //     question : "What is the package cancellation process ?",
    //     answer : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
    // },
    // {
    //     question : "Is there any hidden charge beside the main price?",
    //     answer : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
    // },
    // {
    //     question : "Is there any extra fee to pay for outside US citizen?",
    //     answer : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
    // }
]

const FAQsItem = ({faq})=>{
    const [open, setOpen] = useState(false);
    
    return (
        <div className={`faqs-item`} onClick={()=>setOpen(prev=>!prev)}>
            <div className="faqs-question">
                {faq.question}
                <h3 className="faqs-button">{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h3>
            </div>
            <Collapse in={open} >
                <div className="faqs-bar"></div>
                <div className="faqs-answer">{faq.answer}</div>
            </Collapse>
        </div>
    )
}

const HelpAndSupport = ()=>{
    const [openTicketDialog, setOpenTicketDialog] = useState(false);

    const handleTicketSubmit = async (event)=>{
        event.preventDefault();
        const formData = new FormData(event.target);
        try{
            await raiseTicket(formData);
            toast.success("Ticket submitted", {closeOnClick:true});
        }catch(error){
            toast.error(error.message, {closeOnClick:true});
        }finally{
            setOpenTicketDialog(false);
        }
    }

    return (
        <div className={isMobile ? "home-container" : "help-and-support-container"} style={ isMobile ? {height:"calc(100svh - 4rem - 2.5svh - 1rem - 3rem)"} : null}>
            <DialogForm 
                {...RAISE_A_TICKET}
                open={openTicketDialog}
                onClose={()=>setOpenTicketDialog(false)}
                onSubmit={handleTicketSubmit}
            />
            
            <Paper
                elevation={isMobile ? 0 : 1}
                sx={{
                    width:isMobile ? "100%" : "93%",
                    marginTop:isMobile ? null : "0.5rem",
                    padding:"0.5rem 1rem",
                    maxHeight:"100%",
                    borderRadius:isMobile ? "20px" : "32px",
                    display:"flex",
                    flexDirection:"column",
                    overflow:"hidden",
                    position:"relative",
                }}
            >
                <div className="help-and-support-header">
                    <h4
                        style={{
                            fontSize:"1.4rem",
                            width:"100%",
                            margin:"0",
                            color:"#EA723C",
                            boxSizing:"border-box"
                        }}
                    >Frequently asked questions</h4>
                    <p
                        style={{
                            fontSize:"1rem",
                            margin:"0.5rem 0rem",
                            fontStyle:"italic"
                        }}
                    >Questions you might ask about our products and services</p>    
                </div>
                <div
                    style={{
                        width:"100%",
                        display:"flex",
                        flexGrow:1,
                        flexDirection:"column",
                        alignItems:"center",
                        overflowY:"auto",
                        scrollbarGutter:"stable",
                    }}
                >
                    {
                        FAQsData.map((item, idx)=>(
                            <FAQsItem key={idx} faq={item} />
                        ))
                    }
                </div>

                <p
                    style={{
                        width:"100%",
                        height:"1px",
                        borderBottom:"2px solid lightgray",
                        margin:"1rem 0rem"
                    }}
                ></p>

                <div className="raise-ticket-section">
                    <div>
                        <h4 >Still need help?</h4>
                        <p >Submit a support ticket and our team will assist you.</p>
                    </div>
                    <button className="ticket-button" onClick={()=>setOpenTicketDialog(true)}>
                        Raise a Ticket
                    </button>
                </div>
            </Paper>
        </div>
    )
}

export default HelpAndSupport;